body {
  font-family: 'Arial', sans-serif;
  background-color: #202020;
  color: #fff;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header, .section {
  padding: 20px;
  background-color: #333;
  margin-bottom: 20px;
  border-radius: 10px;
}

h1, h2, h3 {
  color: #ffd700;
}

p, li {
  font-size: 1.2em;
}

.icon {
  color: #ffd700;
  margin-right: 10px;
}

.section ul {
  list-style-type: none;
  padding: 0;
}

.section li {
  margin: 10px 0;
}

a {
  color: #ffd700;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header, .section {
    padding: 15px;
  }

  h1, h2, h3 {
    font-size: 1.5em;
  }
}
